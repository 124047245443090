var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EditorMenuBubble", {
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var commands = ref.commands
              var isActive = ref.isActive
              var getMarkAttrs = ref.getMarkAttrs
              var menu = ref.menu
              return [
                _c(
                  "div",
                  {
                    staticClass: "menububble text-center",
                    class: { menububbleIsActive: menu.isActive },
                    style:
                      "left: " +
                      menu.left +
                      "px; bottom: " +
                      menu.bottom +
                      "px;"
                  },
                  [
                    _vm.linkMenuIsActive
                      ? _c("form", { staticClass: "menububbleForm form" }, [
                          _c("div", { staticClass: "linkBubbleButtons" }, [
                            _c("div", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "BaseInput",
                                    {
                                      attrs: {
                                        label: "New Window",
                                        "is-slider-checkbox": ""
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.isLinkBlank,
                                            expression: "isLinkBlank"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "checkbox",
                                          "true-value": true,
                                          "false-value": false
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.isLinkBlank
                                          )
                                            ? _vm._i(_vm.isLinkBlank, null) > -1
                                            : _vm.isLinkBlank
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.isLinkBlank,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.isLinkBlank = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.isLinkBlank = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.isLinkBlank = $$c
                                            }
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "BaseInput",
                                    {
                                      attrs: {
                                        label: "External Link",
                                        "is-slider-checkbox": ""
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.linkContainsRels,
                                            expression: "linkContainsRels"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "checkbox",
                                          "true-value": true,
                                          "false-value": false
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.linkContainsRels
                                          )
                                            ? _vm._i(
                                                _vm.linkContainsRels,
                                                null
                                              ) > -1
                                            : _vm.linkContainsRels
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.linkContainsRels,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.linkContainsRels = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.linkContainsRels = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.linkContainsRels = $$c
                                            }
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "menububbleFormContainer" },
                            [
                              _c("SearchSelectList", {
                                staticClass: "searchResults",
                                attrs: {
                                  type: _vm.activeType,
                                  "preset-keyword": _vm.superValue
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "esc",
                                        27,
                                        $event.key,
                                        ["Esc", "Escape"]
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.hideBubbleMenu.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.valueChanged
                                }
                              })
                            ],
                            1
                          ),
                          _c("footer", { staticClass: "menububbleFooter" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button closeButton",
                                on: { click: _vm.hideBubbleMenu }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                            ),
                            _vm.activeType === "link"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button confirmButton",
                                    on: {
                                      click: function($event) {
                                        return _vm.setLinkUrl(commands.link, {
                                          url: _vm.superValue,
                                          newWindow: _vm.isLinkBlank,
                                          hasRel: _vm.linkContainsRels
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("confirm")) + " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.activeType === "affiliate"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button confirmButton",
                                    on: {
                                      click: function($event) {
                                        return _vm.setAffiliate(
                                          commands.affiliate,
                                          {
                                            afid: _vm.superValue
                                          }
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("confirm")) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      : [
                          _c("div", { staticClass: "menububbleButtons" }, [
                            _c(
                              "div",
                              {
                                class: [
                                  "menububbleButton",
                                  { isActive: isActive.link() }
                                ],
                                on: {
                                  click: function($event) {
                                    _vm.showBubbleMenu(
                                      getMarkAttrs("link"),
                                      "link"
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      isActive.link()
                                        ? _vm.$t("updateItem", {
                                            item: _vm.$tc("link")
                                          })
                                        : _vm.$t("addItem", {
                                            item: _vm.$tc("link")
                                          })
                                    )
                                  )
                                ]),
                                _c("BaseIcon", {
                                  staticClass: "menububbleIcon",
                                  attrs: {
                                    "icons-group": "fas",
                                    icon: "fa-link"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                class: [
                                  "menububbleButton",
                                  { isActive: isActive.affiliate() }
                                ],
                                on: {
                                  click: function($event) {
                                    _vm.showBubbleMenu(
                                      getMarkAttrs("affiliate"),
                                      "affiliate"
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      isActive.affiliate()
                                        ? _vm.$t("updateItem", {
                                            item: _vm.$tc("affiliate")
                                          })
                                        : _vm.$t("addItem", {
                                            item: _vm.$tc("affiliate")
                                          })
                                    )
                                  )
                                ]),
                                _c("BaseIcon", {
                                  staticClass: "menububbleIcon",
                                  attrs: {
                                    "icons-group": "fas",
                                    icon: "fa-comments-dollar"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                  ],
                  2
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }