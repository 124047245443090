import { render, staticRenderFns } from "./VideoModal.vue?vue&type=template&id=cf414872&scoped=true&"
import script from "./VideoModal.vue?vue&type=script&lang=js&"
export * from "./VideoModal.vue?vue&type=script&lang=js&"
import style0 from "./VideoModal.vue?vue&type=style&index=0&id=cf414872&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf414872",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/pmanikas.social/game-medium-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cf414872')) {
      api.createRecord('cf414872', component.options)
    } else {
      api.reload('cf414872', component.options)
    }
    module.hot.accept("./VideoModal.vue?vue&type=template&id=cf414872&scoped=true&", function () {
      api.rerender('cf414872', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TextEditor/VideoModal.vue"
export default component.exports