<script>
import searchService from '@/services/search.service'
import affiliatesService from '@/services/affiliates.service'
import lazyType from '@/utils/lazy-type'
import webappRoutes from '@/data/enums/webappRoutes'

export default {
  name: 'SearchSelectList',

  props: {
    presetKeyword: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'link',
    }
  },

  data() {
    return {
      lazyTypeDelay: 200,
      searchResults: {},
      isVisible: true,
      keyword: '',
      webappRoutes,
    }
  },

  computed: {
    posts() {
      return this.searchResults.posts || []
    },
    consoles() {
      return this.searchResults.consoles || []
    },
    accessories() {
      return this.searchResults.accessories || []
    },
    brands() {
      return this.searchResults.brands || []
    },
    affiliates() {
      return this.type === 'affiliate' && this.searchResults && this.searchResults.length ? this.searchResults : []
    }
  },

  watch: {
    keyword() {
      this.lazyType(this.search, this.lazyTypeDelay)
      this.$emit('change', this.keyword)
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.linkInput.focus()
    })
    if(!this.keyword && this.presetKeyword) {
      this.keyword = this.presetKeyword
    }
  },

  methods: {
    lazyType,
    search() {
      if(this.keyword.includes('https://')) return
      if(this.keyword && this.type === 'link') {
        searchService.search(this.keyword)
          .then(res => {
            this.isVisible = true
            this.searchResults = res.data
          })
      }else if(this.keyword && this.type === 'affiliate') {
        affiliatesService.getAll()
          .then(res => {
            this.isVisible = true
            this.searchResults = res.data
          })
      }else{
        this.searchResults = {}
      }
    },
    close() {
      this.isVisible = false
    },
    selectItem(model, value) {
      if(model === 'affiliate') {
        this.keyword = value
      }else {
        const url = `${process.env.VUE_APP_WEBAPP_PROD_URL}/${this.webappRoutes[model]}/${value}`
        this.keyword = url
      }
      this.searchResults = {}
      this.isVisible = false
    }
  }
}
</script>

<template>
  <div v-click-outside="close">
    <div class="inputContainer">
      <input
        ref="linkInput"
        v-model="keyword"
        class="input form-control"
        type="text"
        :placeholder="`${$t('enterLinkOrSearch')}...`"
      >
      <span
        class="clearButton"
        @click="keyword ? keyword = '' : $emit('change', '')"
      >
        <BaseIcon
          class="menububbleIcon"
          icons-group="fas"
          icon="fa-times"
        />
      </span>
    </div>
    <div
      v-if="isVisible && (posts.length || brands.length || accessories.length || consoles.length || affiliates.length)"
      class="resultsContainer"
    >
      <ul
        v-if="searchResults && posts.length"
        class="list"
      >
        <h5 class="title">
          {{ $tc('post', 2) }}
        </h5>
        <li
          v-for="item of posts"
          :key="`resultsListPost${item.id}`"
          class="listItem"
          @click="selectItem('post', item.alias)"
        >
          {{ item.name }}
        </li>
      </ul>
      <ul
        v-if="searchResults && consoles.length"
        class="list"
      >
        <h5 class="title">
          {{ $tc('console', 2) }}
        </h5>
        <li
          v-for="item of consoles"
          :key="`resultsListConsole${item.id}`"
          class="listItem"
          @click="selectItem('console', item.alias)"
        >
          {{ item.name }}
        </li>
      </ul>
      <ul
        v-if="searchResults && accessories.length"
        class="list"
      >
        <h5 class="title">
          {{ $tc('accessory', 2) }}
        </h5>
        <li
          v-for="item of accessories"
          :key="`resultsListAccessories${item.id}`"
          class="listItem"
          @click="selectItem('accessory', item.alias)"
        >
          {{ item.name }}
        </li>
      </ul>
      <ul
        v-if="searchResults && brands.length"
        class="list"
      >
        <h5 class="title">
          {{ $tc('brand', 2) }}
        </h5>
        <li
          v-for="item of brands"
          :key="`resultsListBrands${item.id}`"
          class="listItem"
          @click="selectItem('brand', item.alias)"
        >
          {{ item.name }}
        </li>
      </ul>
      <ul
        v-if="affiliates.length"
        class="list"
      >
        <h5 class="title">
          {{ $tc('affiliate', 2) }}
        </h5>
        <li
          v-for="item of affiliates"
          :key="`resultsListAffiliates${item.id}`"
          class="listItem"
          @click="selectItem('affiliate', item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.inputContainer
  position: relative
  display: flex
  align-items: center

.resultsContainer
  position: absolute
  min-width: 330px
  max-height: 300px
  padding: $base-spacing
  overflow: auto
  text-align: left
  background: $limed-spruce

.list
  padding: 0
  margin: 0

.list~.list
  margin-top: $base-spacing

.listItem
  font-size: 12px
  list-style: none
  cursor: pointer
  &:hover
    color: $c-acc-green

.listItem~.listItem
  margin-top: $base-spacing / 3
  
.title
  font-size: 12px
  font-weight: bold

.clearButton
  margin-left: $base-spacing / 2
  line-height: 1
  cursor: pointer
</style>
