var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "lazyload", rawName: "v-lazyload" }],
      staticClass: "editor"
    },
    [
      _c("VideoModal", {
        ref: "ytmodal",
        on: { onConfirm: _vm.addYoutubeCommand }
      }),
      _c("SoundcloudModal", {
        ref: "scmodal",
        on: { onConfirm: _vm.addSoundcloudCommand }
      }),
      _c("SourceCodeModal", {
        ref: "sourcemodal",
        on: { onConfirm: _vm.addSourceCodeCommand }
      }),
      _c("editor-menu-bar", {
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var commands = ref.commands
              var isActive = ref.isActive
              return [
                _c("div", { staticClass: "menubar" }, [
                  _c("div", { staticClass: "menubarTop" }, [
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.bold() },
                        on: { click: commands.bold }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-bold" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.italic() },
                        on: { click: commands.italic }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-italic" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.underline() },
                        on: { click: commands.underline }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-underline" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.strike() },
                        on: { click: commands.strike }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: {
                            "icons-group": "fas",
                            icon: "fa-strikethrough"
                          }
                        })
                      ],
                      1
                    ),
                    _c("span", { staticClass: "menubarButton separator" }),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.paragraph() },
                        on: { click: commands.paragraph }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-paragraph" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.heading({ level: 2 }) },
                        on: {
                          click: function($event) {
                            return commands.heading({ level: 2 })
                          }
                        }
                      },
                      [_vm._v(" H2 ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.heading({ level: 3 }) },
                        on: {
                          click: function($event) {
                            return commands.heading({ level: 3 })
                          }
                        }
                      },
                      [_vm._v(" H3 ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.heading({ level: 4 }) },
                        on: {
                          click: function($event) {
                            return commands.heading({ level: 4 })
                          }
                        }
                      },
                      [_vm._v(" H4 ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.heading({ level: 5 }) },
                        on: {
                          click: function($event) {
                            return commands.heading({ level: 5 })
                          }
                        }
                      },
                      [_vm._v(" H5 ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.heading({ level: 6 }) },
                        on: {
                          click: function($event) {
                            return commands.heading({ level: 6 })
                          }
                        }
                      },
                      [_vm._v(" H6 ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.bullet_list() },
                        on: { click: commands.bullet_list }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-list-ul" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.ordered_list() },
                        on: { click: commands.ordered_list }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-list-ol" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        class: { isActive: isActive.blockquote() },
                        on: { click: commands.blockquote }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-quote-left" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "menubarBottom" }, [
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        on: {
                          click: function($event) {
                            return _vm.showImagePrompt(commands.customImage)
                          }
                        }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-image" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        attrs: {
                          title: _vm.$t("addItem", {
                            item: "Youtube " + _vm.$tc("video")
                          })
                        },
                        on: {
                          click: function($event) {
                            return _vm.openYoutubeModal(commands.iframe)
                          }
                        }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-video" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        attrs: {
                          title: _vm.$t("addItem", {
                            item: "Soundcloud " + _vm.$t("player")
                          })
                        },
                        on: {
                          click: function($event) {
                            return _vm.openSoundcloudModal(commands.soundcloud)
                          }
                        }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-music" }
                        })
                      ],
                      1
                    ),
                    false
                      ? _c(
                          "span",
                          {
                            staticClass: "menubarButton",
                            attrs: {
                              title: _vm.$t("addItem", {
                                item: _vm.$t("sourceCode")
                              })
                            },
                            on: {
                              click: function($event) {
                                return _vm.openSourceCode()
                              }
                            }
                          },
                          [
                            _c("BaseIcon", {
                              attrs: { "icons-group": "fas", icon: "fa-code" }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        on: { click: commands.horizontal_rule }
                      },
                      [_c("BaseIcon", { attrs: { icon: "simple-delete" } })],
                      1
                    ),
                    _c("span", { staticClass: "menubarButton separator" }),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        on: { click: commands.undo }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-undo" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menubarButton",
                        on: { click: commands.redo }
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { "icons-group": "fas", icon: "fa-redo" }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _c("editor-content", {
        staticClass: "form-control editor",
        attrs: { editor: _vm.editor }
      }),
      _c("TextEditorLink", { attrs: { editor: _vm.editor } }),
      _c(
        "Modal",
        {
          attrs: {
            show: _vm.isLibraryModalVisible,
            centered: true,
            "show-close": true
          },
          on: {
            "update:show": function($event) {
              _vm.isLibraryModalVisible = $event
            }
          }
        },
        [
          _vm.isLibraryModalVisible
            ? _c("LibraryList", {
                staticClass: "library",
                attrs: {
                  limit: 40,
                  "grid-item-size": "100",
                  "select-items": "single"
                },
                on: { updateSelected: _vm.updatePhoto }
              })
            : _vm._e(),
          _c("BaseButton", { on: { click: _vm.confirmNewPhoto } }, [
            _vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }