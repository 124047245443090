var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close"
        }
      ]
    },
    [
      _c("div", { staticClass: "inputContainer" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.keyword,
              expression: "keyword"
            }
          ],
          ref: "linkInput",
          staticClass: "input form-control",
          attrs: {
            type: "text",
            placeholder: _vm.$t("enterLinkOrSearch") + "..."
          },
          domProps: { value: _vm.keyword },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.keyword = $event.target.value
            }
          }
        }),
        _c(
          "span",
          {
            staticClass: "clearButton",
            on: {
              click: function($event) {
                _vm.keyword ? (_vm.keyword = "") : _vm.$emit("change", "")
              }
            }
          },
          [
            _c("BaseIcon", {
              staticClass: "menububbleIcon",
              attrs: { "icons-group": "fas", icon: "fa-times" }
            })
          ],
          1
        )
      ]),
      _vm.isVisible &&
      (_vm.posts.length ||
        _vm.brands.length ||
        _vm.accessories.length ||
        _vm.consoles.length ||
        _vm.affiliates.length)
        ? _c("div", { staticClass: "resultsContainer" }, [
            _vm.searchResults && _vm.posts.length
              ? _c(
                  "ul",
                  { staticClass: "list" },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$tc("post", 2)) + " ")
                    ]),
                    _vm._l(_vm.posts, function(item) {
                      return _c(
                        "li",
                        {
                          key: "resultsListPost" + item.id,
                          staticClass: "listItem",
                          on: {
                            click: function($event) {
                              return _vm.selectItem("post", item.alias)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.searchResults && _vm.consoles.length
              ? _c(
                  "ul",
                  { staticClass: "list" },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$tc("console", 2)) + " ")
                    ]),
                    _vm._l(_vm.consoles, function(item) {
                      return _c(
                        "li",
                        {
                          key: "resultsListConsole" + item.id,
                          staticClass: "listItem",
                          on: {
                            click: function($event) {
                              return _vm.selectItem("console", item.alias)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.searchResults && _vm.accessories.length
              ? _c(
                  "ul",
                  { staticClass: "list" },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$tc("accessory", 2)) + " ")
                    ]),
                    _vm._l(_vm.accessories, function(item) {
                      return _c(
                        "li",
                        {
                          key: "resultsListAccessories" + item.id,
                          staticClass: "listItem",
                          on: {
                            click: function($event) {
                              return _vm.selectItem("accessory", item.alias)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.searchResults && _vm.brands.length
              ? _c(
                  "ul",
                  { staticClass: "list" },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$tc("brand", 2)) + " ")
                    ]),
                    _vm._l(_vm.brands, function(item) {
                      return _c(
                        "li",
                        {
                          key: "resultsListBrands" + item.id,
                          staticClass: "listItem",
                          on: {
                            click: function($event) {
                              return _vm.selectItem("brand", item.alias)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.affiliates.length
              ? _c(
                  "ul",
                  { staticClass: "list" },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$tc("affiliate", 2)) + " ")
                    ]),
                    _vm._l(_vm.affiliates, function(item) {
                      return _c(
                        "li",
                        {
                          key: "resultsListAffiliates" + item.id,
                          staticClass: "listItem",
                          on: {
                            click: function($event) {
                              return _vm.selectItem("affiliate", item.id)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }