var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-yoast vue-yoast-snippet-preview" }, [
    _c("div", { staticClass: "vue-yoast-snippet-preview-title" }, [
      _c("span", { ref: "title", domProps: { textContent: _vm._s(_vm.title) } })
    ]),
    _c("div", { staticClass: "vue-yoast-snippet-preview-url" }, [
      _c("span", {
        ref: "url",
        domProps: { textContent: _vm._s(_vm.baseUrl + _vm.url) }
      })
    ]),
    _c("div", { staticClass: "vue-yoast-snippet-preview-description" }, [
      _c("span", {
        ref: "description",
        domProps: { textContent: _vm._s(_vm.description_) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }