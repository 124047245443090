var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "modal" }, [
        _c("div", { staticClass: "modalContent" }, [
          _c("h3", [
            _vm._v(_vm._s(_vm.$t("addItem", { item: _vm.$t("sourceCode") })))
          ]),
          _c("div", { staticClass: "inputArea" }, [
            _c("label", { staticClass: "label", attrs: { for: "code" } }, [
              _vm._v(_vm._s(_vm.$t("pasteYourSourceCode")) + ":  ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.code,
                  expression: "code"
                }
              ],
              ref: "code",
              staticClass: "code",
              attrs: { id: "code", autofocus: "" },
              domProps: { value: _vm.code },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.code = $event.target.value
                }
              }
            })
          ]),
          _c("footer", { staticClass: "modalFooter" }, [
            _c(
              "button",
              {
                staticClass: "button closeButton",
                on: {
                  click: function($event) {
                    _vm.show = false
                    _vm.code = ""
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "button confirmButton",
                attrs: { disabled: !_vm.code },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.insertSourceCode.apply(null, arguments)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }